import React, { Component } from 'react';
import PersonCard from '../Components/Person/PersonCard';
import ActiveSalesCodes from '../Components/ActiveSalesCodes/ActiveSalesCodes';
import HistoricalSalesCodes from '../Components/HistoricalSalesCodes/HistoricalSalesCodes';
import CopyCostCenter from '../Components/CopyCostCenter/CopyCostCenter';
import { PresentationMode } from '../Enums/PresentationMode';
import { BusinessArea } from '../Enums/BusinessArea';
import { Loader } from '@customer/react-relax';
import { Redirect, withRouter } from 'react-router-dom';
import './PersonPage.css';
import { Notification } from '../Elements/Notification';
import {
  isSalesCodeActive,
  getBusinessAreaByChannel,
} from '../Helpers/SalesCodeHelper';
import { GlobalContext } from '../GlobalContext';
import { ServiceClient } from '../Services/ServiceClient';
import { NotificationType } from '../Enums/NotificationType';
import { formatMessage } from '../Localization/formatMessage';
import Messages from '../Localization/Messages';
import { ConvertErrorToNewLines } from '../Helpers/Converter';
import BankAccountHistory from '../Components/BankAccountHistory/BankAccountHistory';
import { LegalUnit } from '../Enums/LegalUnit';
import { ResponsibleType } from '../Enums/ResponsibleType';
import AddressProvider from '../Components/EditAddress/';
import AddressChangeLog from '../Components/AddressChangeLog';

class PersonPage extends Component {
  static contextType = GlobalContext;

  state = {
    item: this.props.location?.state?.item,
    initialTeamValue: this.mapInitialTeamValue(
      this.props.location?.state?.item
    ),
    newSalesId: this.props.location?.state?.newSalesId,
    newName: this.props.location?.state?.newName,
    newUserId: this.props.location?.state?.newUserId,
    enableEditSalesId: this.props.location?.state?.enableEditSalesId,
    editingSalesCode: this.props.match?.params?.editingSalesCode,
    redirect: false,
    notifications: [],
    isCostCenterCopyOpen: false,
    isBankAccountHistoryOpen: false,
    isAddressTabOpen: false,
    isAddressChangeLogOpen: false,
    isHistoricalSalesCodesOpen: false,
    addressResponsibleData: '',
    mode: this.props.mode,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      this.updateBreadcrumbs();
      if (this.props.location?.pathname === '/person/add') {
        //If after remove all salescodes, switched to AddUser Page
        this.setState({
          item: {
            salesId: this.props.location.state?.item.salesId,
            userId: this.props.location.state?.item.userId,
            name: this.props.location.state?.item.name,
          },
        });
      } else if (this.state.mode !== PresentationMode.Edit) {
        // update item
        if (this.props.location?.state?.item) {
          this.setState({
            item: this.props.location?.state?.item,
            editingSalesCode: this.props.match?.params?.editingSalesCode,
          });
        } else {
          this.setState(
            {
              isLoading: true,
              editingSalesCode: this.props.match?.params?.editingSalesCode,
            },
            this.loadItem({ showError: false })
          );
        }
      }
    }

    if (
      prevProps.location?.state?.notifications !==
      this.props.location?.state?.notifications
    ) {
      this.clearNotifications();
    }
  }

  componentDidMount() {
    this.updateBreadcrumbs();
    if (this.state.mode !== PresentationMode.Edit) {
      this.setState(
        {
          isLoading: true,
        },
        this.loadItem
      );
    }
  }

  render() {
    return this.state.isLoading ? (
      <div>
        <Loader />
      </div>
    ) : this.ifAddUserLinkOpenedDirectly() ? (
      this.redirectToMainPage()
    ) : (
      <div className="person-page-main">
        {this.state.notifications.map((notification, i) => (
          <Notification key={i} type={notification.type}>
            {notification.text}
          </Notification>
        ))}
        <section className="relax-mesh">
          <PersonCard
            item={this.state.item}
            mode={this.state.mode}
            handleSaveButton={this.handleSaveButton}
            handleCancelButton={this.handleCancelButton}
            modeHandler={this.setMode}
            newSalesId={this.state.newSalesId}
            newName={this.state.newName}
            newUserId={this.state.newUserId}
            openCostCenterCopyHandler={() =>
              this.setState({ isCostCenterCopyOpen: true })
            }
            canOpenCostCenterCopyHandler={this.state.item?.salesCodes?.some(
              isSalesCodeActive
            )}
            viewBankAccountLogHandler={() => {
              this.setState({ isBankAccountHistoryOpen: true });
            }}
            openAddressTab={this.openAddressTab}
            openAddressChangeLog={this.openAddressChangeLog}
            isDecoupledFunctionsAvailable={this.isDecoupledFunctionsAvailable()}
            isBrokerFunctionsAvailable={this.isBrokerFunctionsAvailable()}
            isBankAccountMandatory={this.isBankAccountMandatory()}
            enableEditSalesId={this.state.enableEditSalesId}
          />
        </section>
        <ActiveSalesCodes
          item={this.state.item}
          mode={this.state.mode}
          setItem={this.setItem.bind(this)}
          addSalesCode={this.addSalesCode}
          personReload={this.loadItem}
          updateSalesCode={this.updateSalesCode}
          deleteSalesCode={this.deleteSalesCode}
          newSalesId={this.state.newSalesId}
          newUserId={this.state.newUserId}
          isBrokerFunctionsAvailable={this.isBrokerFunctionsAvailable()}
          isDecoupledFunctionsAvailable={this.isDecoupledFunctionsAvailable()}
          editingSalesCode={this.state.editingSalesCode}
          openAddressTab={this.openAddressTab}
          openAddressChangeLog={this.openAddressChangeLog}
        />

        <p
          onClick={() => {
            this.setState({
              isHistoricalSalesCodesOpen: !this.state
                .isHistoricalSalesCodesOpen,
            });
          }}>
          {this.state.isHistoricalSalesCodesOpen
            ? formatMessage(Messages.personPageHideHistorical)
            : formatMessage(Messages.personPageShowHistorical)}
        </p>
        {this.state.isHistoricalSalesCodesOpen && (
          <HistoricalSalesCodes item={this.state.item} mode={this.state.mode} />
        )}

        <CopyCostCenter
          isShown={this.state.isCostCenterCopyOpen}
          setIsShown={x => this.setState({ isCostCenterCopyOpen: x })}
          salesCodes={this.state.item?.salesCodes}></CopyCostCenter>
        <BankAccountHistory
          isShown={this.state.isBankAccountHistoryOpen}
          salesId={this.state.item?.salesId}
          setIsShown={x =>
            this.setState({ isBankAccountHistoryOpen: x })
          }></BankAccountHistory>
        {this.state.isAddressTabOpen && (
          <AddressProvider
            setIsShown={value => this.setState({ isAddressTabOpen: value })}
            agentCode={this.state.addressResponsibleData}
            codePrefix={this.state.item.salesId}
          />
        )}
        {this.state.isAddressChangeLogOpen && (
          <AddressChangeLog
            setIsShown={value =>
              this.setState({ isAddressChangeLogOpen: value })
            }
            agentCode={this.state.addressResponsibleData}
            codePrefix={this.state.item.salesId}
          />
        )}
      </div>
    );
  }

  mapGetTeamEndpoint = result => {
    return {
      ...result,
      salesId: result.codePrefix,
      salesCodes: result?.agents.map(agent => ({
        ...agent,
        salesCode: agent.code,
      })),
    };
  };

  loadItem = (showError = true, redirectToAddPage = false) => {
    ServiceClient.getTeam(
      this.props.match.params.salesId,
      result => {
        const mappedResult = this.mapGetTeamEndpoint(result);
        this.setState({
          item: mappedResult,
          isLoading: false,
          initialTeamValue: this.mapInitialTeamValue(mappedResult),
        });
      },
      () => {
        if (showError) this.props.setError(404);
        if (redirectToAddPage) {
          this.props.history.push({
            pathname: '/person/add',
            state: {
              item: {
                salesId: this.state.item?.salesId,
                userId: this.state.item?.userId,
                name: this.state.item?.name,
              },
            },
          });
        }
      }
    );
  };

  clearNotifications() {
    // if there is not notifications, skip
    if (!this.props.location?.state?.notifications) {
      return;
    }

    this.setState({
      notifications: this.props.location?.state?.notifications ?? [],
    });
    // remove notifications from location.state
    let { notifications, ...state } = this.props.location?.state;
    this.props.history.replace({
      pathname: this.props.location?.pathname,
      state: state,
    });
  }

  handleSaveButton = async (
    salesId,
    userId,
    name,
    bankAccountNo,
    bankAccountType
  ) => {
    const internalUpdateTeamInfo = updatedItem => {
      updatedItem = this.mapGetTeamEndpoint(updatedItem);
      this.setState({
        item: updatedItem ?? {
          ...this.state.item,
          salesId: salesId,
          userId: userId,
          name: name,
          bankAccountNo: bankAccountNo,
          bankAccountType: bankAccountType,
        },
        mode: PresentationMode.View,
      });
    };
    if (this.state.item?.salesId) {
      ServiceClient.updateTeamInfo(
        this.getFieldsChangedForTeam(this.state.initialTeamValue, {
          userId,
          name,
          bankAccountNo,
        }),
        this.state.item?.salesId,
        userId,
        name,
        bankAccountNo,
        bankAccountType,
        updatedItem => {
          internalUpdateTeamInfo(updatedItem);
        },
        (message, error, context) => {
          const errorMessage = ConvertErrorToNewLines(context.errorTexts);
          this.pushNotification(NotificationType.Error, errorMessage);
          return true;
        }
      );
    } else {
      //If doesnt exist any salescode, update only UI
      let isValidSaveProcess = true;

      if (userId?.trim()) {
        const userIdExistsInAD = await this.isUserIdExistsInAD(userId);

        if (!userIdExistsInAD) {
          isValidSaveProcess = false;

          this.pushNotification(
            NotificationType.Error,
            formatMessage(Messages.errorMessage_14)
          );
        }

        const userIdExistsInDB = await this.isUserIdExistsInDB(salesId);

        if (userIdExistsInDB) {
          isValidSaveProcess = false;

          this.pushNotification(
            NotificationType.Error,
            formatMessage(Messages.errorMessage_1)
          );
        }

        if (isValidSaveProcess) {
          internalUpdateTeamInfo();
        }
      } else {
        internalUpdateTeamInfo();
      }
    }
  };

  isUserIdExistsInDB = userId => {
    return new Promise(resolve => {
      ServiceClient.checkCodePrefixUniqueness(
        userId,
        null,
        notFoundCallback => {
          resolve(false);
        },
        foundCallback => {
          resolve(true);
        }
      );
    });
  };

  isUserIdExistsInAD = userId => {
    return new Promise(resolve => {
      ServiceClient.getUserDetailsFromADbyUserID(userId, foundCallback => {
        resolve(foundCallback);
      });
    });
  };

  pushNotification(type, text) {
    this.setState({
      notifications: [...this.state.notifications, { type, text }],
    });
  }

  setItem = data => {
    this.setState({
      item: data,
    });
  };

  addSalesCode = salescode => {
    var salescodes = this.state.item.salesCodes
      ? [...this.state.item.salesCodes, salescode]
      : [salescode];

    this.setState({
      item: { ...this.state.item, salesCodes: salescodes },
    });
  };

  updateSalesCode = salescode => {
    console.log('updating salescode');
    console.log(salescode);
    var salescodes = this.state.item.salesCodes
      ? [...this.state.item.salesCodes]
      : [salescode];
    var index = salescodes.findIndex(x => x.salesCode === salescode.salesCode);
    salescodes[index] = salescode;

    this.setState({
      item: { ...this.state.item, salesCodes: salescodes },
    });
  };

  deleteSalesCode = salescodeString => {
    console.log('deleting salescode');
    console.log(salescodeString);
    var salescodes = this.state.item.salesCodes
      ? [...this.state.item.salesCodes]
      : [];
    salescodes = salescodes.filter(x => x.salesCode !== salescodeString);

    this.setState({
      item: { ...this.state.item, salesCodes: salescodes },
    });
  };

  handleCancelButton = () => {
    if (this.state.item) {
      this.setMode(PresentationMode.View);
      return;
    }
    window.history.back();
  };

  setMode = mode => {
    this.setState({
      mode,
    });
  };

  ifAddUserLinkOpenedDirectly = () => {
    return (
      !this.state.item &&
      this.state.newSalesId === undefined &&
      this.state.mode === PresentationMode.Edit
    );
  };

  redirectToMainPage = () => {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  };

  getBusinessArea() {
    return getBusinessAreaByChannel(this.state?.item?.salesCodes[0]?.channel);
  }

  getLegalUnit() {
    return this.state?.item?.salesCodes[0]?.region?.legalUnitName;
  }

  getResponsibleType() {
    return this.state?.item?.responsibleType;
  }

  isSalesCodeExist() {
    return !!this.state?.item?.salesCodes;
  }

  isDecoupledFunctionsAvailable() {
    //If it is Private BA or SE Com Internal (decoupled) then shouldn't use BankAccount and extension parameters "Group Email", "Digital", "XML Export".
    //Otherwise if it is commercial extension functionalities shoud be available.But name shouldn't displayed and  rename operation not allowed
    return (
      !this.isSalesCodeExist() ||
      this.getBusinessArea() === BusinessArea.Private ||
      (this.getLegalUnit() === LegalUnit.Sweden &&
        this.getBusinessArea() === BusinessArea.Commercial &&
        this.getResponsibleType() === ResponsibleType.Internal)
    );
  }

  isBrokerFunctionsAvailable() {
    return !this.isDecoupledFunctionsAvailable();
  }

  isBankAccountMandatory() {
    return (
      !this.isDecoupledFunctionsAvailable() &&
      this.getLegalUnit() !== LegalUnit.Finland
    );
  }

  updateBreadcrumbs() {
    this.props.navigationReset(() => {
      var urlWithoutSalesCode = (this.props.location?.pathname ?? '').replace(
        /\/(\w+)\/\1-\d\/?$/i,
        '/$1'
      );
      this.props.navigationAppend('person', urlWithoutSalesCode);
    });
  }

  openAddressTab = salesCode => {
    this.setState({
      addressResponsibleData: salesCode,
      isAddressTabOpen: true,
    });
  };

  openAddressChangeLog = salesCode => {
    this.setState({
      addressResponsibleData: salesCode,
      isAddressChangeLogOpen: true,
    });
  };

  mapInitialTeamValue(item) {
    return {
      codePrefix: item?.salesId,
      userId: item?.userId,
      name: item?.name,
      bankAccountNo: item?.bankAccount?.number,
    };
  }

  getFieldsChangedForTeam(initialFields, newFields) {
    let sum = 0;
    if (initialFields?.userId !== newFields?.userId) {
      sum = sum + 1;
    }
    if (initialFields?.name !== newFields?.name) {
      sum = sum + 2;
    }
    if (initialFields?.bankAccountNo !== newFields?.bankAccountNo) {
      sum = sum + 4;
    }
    return sum;
  }
}

export default withRouter(PersonPage);

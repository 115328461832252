import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useReducer,
} from 'react';
import { addressActions, addressTypes } from './AddressConstants';
import { ServiceClient } from '../../Services/ServiceClient';
import { addressReducer } from './AddressProviderFunctions';
import AddressView from './Components/AddressView';
import SalesAgentModal from '../../Elements/SalesAgentModal';
import AddressButtons from './Components/AddressButtons';
import { Loader } from '@customer/react-relax';
import Warning from './Components/Warning';
import Error from './Components/Error';

const GlobalAddressContext = createContext();

export const useGlobalAddressContext = () => useContext(GlobalAddressContext);

const AddressProvider = ({ setIsShown, agentCode, codePrefix }) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [addressAction, setAddressAction] = useState(addressActions.Loading);
  const [countryList, setCountryList] = useState([]);

  const isModalOpenedOnTeamLevel =
    agentCode === null || agentCode === undefined;

  const [addressState, dispatch] = useReducer(addressReducer, {
    addressType: addressTypes.Primary,
    selectedAddress: {},
    addressList: [],
  });

  useEffect(() => {
    ServiceClient.getCountries(data => {
      setCountryList(data);
      ServiceClient.getAddressListForTeam(codePrefix, data => {
        if (data.length === 0) {
          setAddressAction(addressActions.Error);
        } else {
          dispatch({
            type: 'setAddressList',
            addressList: data,
          });
          ServiceClient.getAddressForTeam(codePrefix, teamAddressDto => {
            if (isModalOpenedOnTeamLevel) {
              dispatch({
                type: 'setInitialAddressStateTeam',
                teamAddressDto,
              });
              setAddressAction(addressActions.View);
            } else {
              ServiceClient.getAddressForAgent(agentCode, agentAddressDto => {
                dispatch({
                  type: 'setInitialAddressStateAgent',
                  agentAddressDto,
                  teamAddressDto,
                });
                setAddressAction(addressActions.View);
              });
            }
          });
        }
      });
    });
  }, [agentCode, codePrefix, isModalOpenedOnTeamLevel]);

  return (
    <SalesAgentModal
      setIsShown={setIsShown}
      titleText={agentCode ?? codePrefix}
      buttons={
        <AddressButtons
          canSubmit={canSubmit}
          addressAction={addressAction}
          setAddressAction={setAddressAction}
          setIsShown={setIsShown}
          dispatch={dispatch}
          addressState={addressState}
        />
      }
      action={addressAction}>
      <GlobalAddressContext.Provider
        value={{
          setCanSubmit,
          addressAction,
          setAddressAction,
          isModalOpenedOnTeamLevel,
          addressState,
          dispatch,
          countryList,
          agentCode,
          codePrefix,
        }}>
        {addressAction === addressActions.Loading ? (
          <Loader />
        ) : addressAction === addressActions.Warning ? (
          <Warning />
        ) : addressAction === addressActions.Error ? (
          <Error />
        ) : (
          <AddressView />
        )}
      </GlobalAddressContext.Provider>
    </SalesAgentModal>
  );
};

export default AddressProvider;

import React from 'react';
import { addressActions } from '../../AddressConstants';
import { formatMessage } from '../../../../Localization/formatMessage';
import Messages from '../../../../Localization/Messages';

const AddressButtons = ({
  canSubmit,
  addressAction,
  setAddressAction,
  setIsShown,
  dispatch,
  addressState,
}) => {
  return (
    <>
      <button
        type="button"
        className="relax-button"
        onClick={() => {
          if (
            addressAction !== addressActions.View &&
            addressAction !== addressActions.Error &&
            addressState.addressList.length !== 0
          ) {
            dispatch({
              type: 'resetSelectedAddress',
            });

            setAddressAction(addressActions.View);
          } else {
            setIsShown(false);
          }
        }}>
        {formatMessage(Messages.cancel)}
      </button>

      {(addressAction === addressActions.Edit ||
        addressAction === addressActions.Create) && (
        <button
          type="submit"
          form="addressForm"
          className="relax-button relax-button--primary"
          disabled={!canSubmit}>
          {addressAction === addressActions.Edit
            ? formatMessage(Messages.update)
            : formatMessage(Messages.create)}
        </button>
      )}
    </>
  );
};

export default AddressButtons;

import axios from 'axios';
import config from '../config/variables';
import querystring from 'querystring';
import { generateElementId } from '../Helpers/ElementIdGenerator';
import {
  ServiceCallbackErrorHandler,
  ServiceErrorResolutionContext,
} from './ServiceErrorHandler';
import { getLocalizationForErrorMessage } from '../Localization/getLocalizationForErrorMessage';
import { ResultErrorCode } from '../Enums/ResultErrorCode';
import {
  logRequestInterceptor,
  logRequestErrorInterceptor,
  logResponseInterceptor,
  logResponseErrorInterceptor,
} from './LogInterceptors';

//#region Common

/**
 *
 * @param {*} axiosConfig Usual Axios config, but properties validStatuses, invalidStatuses could be attached
 */
const axiosCreate = function(axiosConfig) {
  const {
    validStatuses,
    invalidStatuses,
    failedIfHasErrorsField,
    ...fullAxiosConfig
  } = {
    withCredentials: true,
    validateStatus: function(status) {
      if (validStatuses && validStatuses.includes(status)) {
        return true;
      }
      if (invalidStatuses && invalidStatuses.includes(status)) {
        return false;
      }
      return axios.defaults.validateStatus(status);
    },
    transformRequest: [
      function(data, headers) {
        return data;
      },
      // defaults should be after custom transformRequest
      ...axios.defaults.transformRequest,
    ],
    // defaults should be before custom transformResponse
    transformResponse: axios.defaults.transformResponse.concat(
      (data, headers) => {
        return data;
      }
    ),
    ...axiosConfig,
  };

  var axiosInstance = axios.create(fullAxiosConfig);
  axiosInstance.interceptors.request.use(
    logRequestInterceptor,
    logRequestErrorInterceptor
  );
  axiosInstance.interceptors.response.use(
    logResponseInterceptor,
    logResponseErrorInterceptor
  );
  return axiosInstance;
};

/**
 * This method calls function with arguments and returns result or does nothing if there is no function
 *
 * @param {*} func
 * @param  {...any} args
 */
const tryCall = function(func, ...args) {
  if (func && typeof func === 'function') {
    return func.apply(this, args);
  }
  return undefined;
};

/**
 * Modified promise.then with support of cancellation callback and error handlers. Returns Promise
 *
 * @param {*} successCallback
 * @param {*} errorCallback
 * @param {*} cancelledCallback
 * @param {*} errorHandlers
 */
// eslint-disable-next-line no-extend-native
Promise.prototype.then2 = function(
  successCallback,
  errorCallback,
  cancelledCallback,
  errorHandlers,
  isExternalAPI = false
) {
  const handleErrors = function(errors) {
    // convert errorCallback to ServiceErrorHandler
    const callbackHandler = errorCallback
      ? new ServiceCallbackErrorHandler(errorCallback)
      : null;
    // insert callbackHandler and reorder handlers queue
    const handlers = Array.from(errorHandlers?.values() ?? {})
      .concat(callbackHandler ? [callbackHandler] : [])
      .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));

    const errorTexts = errors.map(
      err =>
        getLocalizationForErrorMessage(err.code, err.argument) ?? err.message
    );
    const handledErrorsIndexes = [];
    // handle errors
    for (const handler of handlers) {
      let stopHandlingOtherErrors = false;
      for (let errorIndex = 0; errorIndex < errors.length; errorIndex++) {
        // skip current error processing if it was handled earlier
        if (handledErrorsIndexes.includes(errorIndex)) {
          continue;
        }

        const resolutionContext = new ServiceErrorResolutionContext(
          errors,
          errorTexts,
          errorIndex
        );
        const handlingResult = handler.handleError(resolutionContext);
        if (handlingResult.stopHandlingOtherErrors) {
          // exit from both loops
          stopHandlingOtherErrors = true;
          break;
        }
        if (handlingResult.handled) {
          handledErrorsIndexes.push(errorIndex);
        }
      }
      if (stopHandlingOtherErrors) {
        break;
      }
    }
  };

  return this.then(
    function(response) {
      const errors = response?.data?.errors ?? [];
      if (errors.length) {
        handleErrors(errors);
      } else if (isExternalAPI) {
        tryCall(successCallback, response.data);
      } else {
        tryCall(successCallback, response.data.value);
      }
    },
    function(error) {
      if (axios.isCancel(error)) {
        tryCall(cancelledCallback);
      } else {
        const response = error?.response;
        const errors = response?.data?.errors ?? [
          {
            code: ResultErrorCode.HttpResponseException,
            message: response?.statusText,
            argument: response?.status,
          },
        ];
        handleErrors(errors);
      }
    }
  );
};

//#endregion

class ServiceClientClass {
  globalErrorHandlers = new Map();

  registerGlobalErrorHandler(errorHandler) {
    const newIdentifier = generateElementId(8);
    this.globalErrorHandlers.set(newIdentifier, errorHandler);
    return newIdentifier;
  }

  unregisterGlobalErrorHandler(errorHandlerId) {
    this.globalErrorHandlers.delete(errorHandlerId);
  }

  createCancellationToken() {
    return axios.CancelToken.source();
  }

  getChannels(callback) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/channels/tree`)
      .then2(
        result => {
          tryCall(callback, result);
        },
        null,
        null,
        this.globalErrorHandlers
      );
  }

  //#region Address endpoints

  getCountries(callback) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/countries`)
      .then2(
        result => {
          tryCall(callback, result);
        },
        null,
        null,
        this.globalErrorHandlers
      );
  }

  createAddressForAgent = (
    agentCode,
    addressData,
    successCallback,
    errorCallBack
  ) => {
    return axiosCreate()
      .post(`${config.apis.SalesAgent}/agents/${agentCode}/address`, {
        ...addressData,
      })
      .then2(successCallback, null, null, this.globalErrorHandlers);
  };

  createAddressForTeam = (
    codePrefix,
    addressData,
    successCallback,
    errorCallBack
  ) => {
    return axiosCreate()
      .post(`${config.apis.SalesAgent}/teams/${codePrefix}/addresses/1`, {
        ...addressData,
      })
      .then2(successCallback, null, null, this.globalErrorHandlers);
  };

  getAddressForAgent = (agentCode, callback) => {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/agents/${agentCode}/address`)
      .then2(
        result => {
          tryCall(callback, result);
        },
        null,
        null,
        null
      );
  };

  getAddressChangeHistoryForAgent = (agentCode, callback) => {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/agents/${agentCode}/address/history`)
      .then2(
        result => {
          tryCall(callback, result);
        },
        null,
        null,
        null
      );
  };

  getAddressForTeam = (codePrefix, callback) => {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/teams/${codePrefix}/addresses`)
      .then2(
        result => {
          tryCall(callback, result);
        },
        null,
        null,
        null
      );
  };

  getAddressChangeHistoryForTeam = (codePrefix, callback) => {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/teams/${codePrefix}/addresses/history`)
      .then2(
        result => {
          tryCall(callback, result);
        },
        null,
        null,
        null
      );
  };

  getAddressListForTeam = (codePrefix, callback) => {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/teams/${codePrefix}/agents/addresses`)
      .then2(
        result => {
          tryCall(callback, result);
        },
        null,
        null,
        null
      );
  };

  inheritAddressFromTeam = (agentCode, callback) => {
    return axiosCreate()
      .delete(`${config.apis.SalesAgent}/agents/${agentCode}/address`, {})
      .then2(callback, null, null, null);
  };

  setAddressForAgent = (agentCode, addressId, callback) => {
    return axiosCreate()
      .post(
        `${config.apis.SalesAgent}/agents/${agentCode}/address/${addressId}`,
        {}
      )
      .then2(callback, null, null, null);
  };

  setAddressForTeam = (codePrefix, addressId, callback) => {
    return axiosCreate()
      .post(
        `${config.apis.SalesAgent}/teams/${codePrefix}/addresses/1/${addressId}`,
        {}
      )
      .then2(callback, null, null, null);
  };

  searchBuildingRegistry = (query, legalUnit, callback, errorCallBack) => {
    return axiosCreate()
      .get(
        `/BuildingRegistry.Web/api/AddressSearch/${query}?legalUnit=${legalUnit}`
      )
      .then2(
        result => {
          tryCall(callback, result);
        },
        errorCallBack,
        null,
        null,
        true
      );
  };

  searchPostalDistrict = (postalCode, countryCode, callback) => {
    return axiosCreate({ withCredentials: false })
      .get(
        `https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=if.no&pnr=${postalCode}&country=${countryCode}`
      )
      .then2(
        result => {
          tryCall(callback, result);
        },
        null,
        null,
        null,
        true
      );
  };

  //#endregion

  getUserDetailsFromADbyUserID = (username, successCallback) => {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/ad/users/${username}`)
      .then2(
        result => {
          tryCall(successCallback, result);
        },
        null,
        null,
        this.globalErrorHandlers
      );
  };

  checkCodePrefixUniqueness(
    codePrefix,
    cancelToken,
    uniqueCallback,
    notUniqueCallback,
    requestCancelledCallback,
    errorCallback
  ) {
    return axiosCreate({ cancelToken: cancelToken?.token })
      .get(`${config.apis.SalesAgent}/teams/${codePrefix}/exists`)
      .then2(
        result => {
          if (result) {
            tryCall(notUniqueCallback);
          } else {
            tryCall(uniqueCallback);
          }
        },
        errorCallback,
        requestCancelledCallback,
        this.globalErrorHandlers
      );
  }

  findTeams(query, cancelToken, successCallback) {
    return axiosCreate({ cancelToken: cancelToken?.token })
      .get(
        `${config.apis.SalesAgent}/agents?${querystring.stringify({
          query: query,
        })}`
      )
      .then2(
        result => {
          tryCall(successCallback, result?.teams);
        },
        null,
        null,
        this.globalErrorHandlers
      );
  }

  getTeam(codePrefix, successCallback, errorCallBack = null) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/teams/${codePrefix}`)
      .then2(
        result => {
          tryCall(successCallback, result);
        },
        errorCallBack,
        null,
        this.globalErrorHandlers
      );
  }

  updateTeamInfo = (
    fields,
    codePrefix,
    userId,
    name,
    bankAccountNo,
    bankAccountType,
    successCallback,
    errorCallBack
  ) => {
    return axiosCreate()
      .patch(`${config.apis.SalesAgent}/teams/${codePrefix}`, {
        fields,
        samAccountName: userId,
        name,
        bankAccountNo,
        bankAccountType,
      })
      .then2(successCallback, errorCallBack, null, this.globalErrorHandlers);
  };

  editSalesCode(
    agentCode,
    costCenterId,
    externalSalesCode,
    additionalName,
    email,
    phone,
    canBePortfolioResponsible,
    extension,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .patch(`${config.apis.SalesAgent}/agents/${agentCode}`, {
        fields: 127, //We are updating all parameters together
        costCenterId,
        externalSalesCode,
        additionalName,
        email,
        phone,
        canBePortfolioResponsible,
        extension,
      })
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  revokeAgentCode(agentCode, successCallback) {
    return axiosCreate()
      .delete(`${config.apis.SalesAgent}/agents/${agentCode}`)
      .then2(successCallback, null, null, this.globalErrorHandlers);
  }

  createTeam(codePrefix, data, successCallback, errorCallback) {
    return axiosCreate()
      .post(`${config.apis.SalesAgent}/teams/${codePrefix}`, { ...data })
      .then2(
        result => {
          tryCall(successCallback, result);
        },
        errorCallback,
        null,
        this.globalErrorHandlers
      );
  }

  createAgent(
    codePrefix,
    costCenterId,
    externalSalesCode,
    additionalName,
    email,
    phone,
    canBePortfolioResponsible,
    extension,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .post(`${config.apis.SalesAgent}/teams/${codePrefix}/agents`, {
        costCenterId: costCenterId,
        externalSalesCode: externalSalesCode,
        additionalName: additionalName,
        email: email,
        phone: phone,
        canBePortfolioResponsible: canBePortfolioResponsible,
        extension: extension,
      })
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getDefaultBankAccountLog(codePrefix, successCallback, errorCallback) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/teams/${codePrefix}/bankAccount/history`)
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getBankAccountHistoryForAgent(agentCode, successCallback, errorCallback) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/agents/${agentCode}/bankAccount/history`)
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getBankAccountForAgent(agentCode, successCallback, errorCallback) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/agents/${agentCode}/bankAccount`)
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getBankAccountForTeam(codePrefix, successCallback, errorCallback) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/teams/${codePrefix}/bankAccount`)
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  setBankAccountForTeam(
    codePrefix,
    bankAccountNo,
    bankAccountType,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .post(`${config.apis.SalesAgent}/teams/${codePrefix}/bankAccount`, {
        bankAccountNo: bankAccountNo,
        bankAccountType: bankAccountType,
      })
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  setBankAccountForAgent(
    agentCode,
    bankAccountNo,
    bankAccountType,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .post(`${config.apis.SalesAgent}/agents/${agentCode}/bankAccount`, {
        bankAccountNo: bankAccountNo,
        bankAccountType: bankAccountType,
      })
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getBrokerCostRates(
    agentCode,
    customerNumber,
    allowTransformToNetPrice,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .get(
        `${
          config.apis.SalesAgent
        }/brokers/${agentCode}/costRates/customers/${customerNumber}?${querystring.stringify(
          {
            allowTransformToNetPrice: allowTransformToNetPrice,
          }
        )}`
      )
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getBrokerCostsActualRatesHistory(
    agentCode,
    customerNumber,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .get(
        `${config.apis.SalesAgent}/brokers/${agentCode}/costRates/history/customers/${customerNumber}`
      )
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  setBrokerCostRates(agentCode, newRates, successCallback, errorCallback) {
    return axiosCreate()
      .post(
        `${config.apis.SalesAgent}/brokers/${agentCode}/costRates`,
        newRates
      )
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  setBrokerCostRatesForCustomer(
    agentCode,
    customerNumber,
    newRates,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .post(
        `${config.apis.SalesAgent}/brokers/${agentCode}/costRates/customers/${customerNumber}`,
        newRates
      )
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getCustomersWithDeviatedBrokerCost(
    agentCode,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/brokers/${agentCode}/customers`)
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getCustomerSlim(customerNumberOrLegalNumber, successCallback, errorCallback) {
    return axiosCreate()
      .get(
        `${config.apis.Customer}/getCustomerSlim?${querystring.stringify({
          number: customerNumberOrLegalNumber,
        })}`
      )
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getAllCurrentBrokerRates(agentCode, successCallback, errorCallback) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/brokers/${agentCode}/costRates/complete`)
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  findCustomers(text, successCallback, errorCallback) {
    return axiosCreate()
      .get(
        `${config.apis.Customer}/findCustomersSlim?${querystring.stringify({
          text: text,
        })}`
      )
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getAgentCodeConversions(successCallback, errorCallback) {
    return axiosCreate()
      .get(`${config.apis.SalesAgent}/agentCodeConversions`)
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  enableAgentCodeConversionForRenewal(
    oldCode,
    newCode,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .post(`${config.apis.SalesAgent}/agentCodeConversions/${oldCode}`, {
        newCode: newCode,
      })
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  disableAgentCodeConversionForRenewal(
    oldCode,
    successCallback,
    errorCallback
  ) {
    return axiosCreate()
      .delete(`${config.apis.SalesAgent}/agentCodeConversions/${oldCode}`, {})
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }

  getUser(successCallback, errorCallback) {
    return axiosCreate()
      .get(`/auth/getUser`)
      .then2(successCallback, errorCallback, null, this.globalErrorHandlers);
  }
}

export const ServiceClient = new ServiceClientClass();

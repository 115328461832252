import React, { memo, useEffect, useState } from 'react';
import SalesAgentModal from '../../Elements/SalesAgentModal';
import { formatMessage } from '../../Localization/formatMessage';
import Messages from '../../Localization/Messages';
import './AddressChangeLog.css';
import { ServiceClient } from '../../Services/ServiceClient';
import FormattedAddress from './Components/FormattedAddress';

const AddressChangeLog = ({ setIsShown, agentCode, codePrefix }) => {
  const [list, setList] = useState([]);

  const isOpenedOnTeamLevel = agentCode === undefined;

  useEffect(() => {
    if (isOpenedOnTeamLevel) {
      ServiceClient.getAddressChangeHistoryForTeam(
        codePrefix,
        teamAddressLogDto => {
          setList(teamAddressLogDto.postal);
        }
      );
    } else {
      ServiceClient.getAddressChangeHistoryForAgent(
        agentCode,
        agentAddressLogDto => {
          setList(agentAddressLogDto);
        }
      );
    }
  }, [agentCode, codePrefix, isOpenedOnTeamLevel]);

  return (
    <SalesAgentModal
      setIsShown={setIsShown}
      titleText={`${formatMessage(
        Messages.addressChangeLogTitle
      )} ${agentCode ?? codePrefix}`}>
      <div className="address-change-log-container">
        <table className="relax-table relax-table--striped">
          <thead>
            <tr>
              <th>{formatMessage(Messages.addressChangeLogTimeStamp)}</th>
              <th>{formatMessage(Messages.addressChangeAddress)}</th>
              {!isOpenedOnTeamLevel && (
                <th>{formatMessage(Messages.addressChangeLogInherited)}</th>
              )}
              <th>{formatMessage(Messages.addressChangeLogUpdater)}</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => (
              <tr key={index}>
                <td>{item.updated}</td>
                <td>
                  <FormattedAddress address={item.address} />
                </td>
                {!isOpenedOnTeamLevel && (
                  <td>{item.inherited ? 'true' : 'false'}</td>
                )}

                <td>{item.updatedBy}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </SalesAgentModal>
  );
};

export default memo(AddressChangeLog);
